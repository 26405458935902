'use client';

import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';
import { ArrowWithPlume } from '@/components/Shapes';
import { ContactFrom } from '@/components/Forms/Contact';
import { Typography } from '@/atoms/00-ui/Typography';
import './index.scss';

interface ContactFormPopupProps {
  title: ReactNode;
  subtitle: string;
  descriptionSlot?: ReactNode;
  show: boolean;
  onClose: VoidFunction;
  isAudit?: boolean;
}

export const ContactFormPopup = ({
  title,
  subtitle,
  descriptionSlot,
  show,
  onClose,
  isAudit = false,
}: ContactFormPopupProps) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    const header = document.body.querySelector('header');
    document.body.style.overflow = show ? 'hidden' : 'unset';
    if (header) header.style.overflowY = show ? 'scroll' : 'unset';

    if (show) {
      setIsFormSubmitted(false);
    }

    return () => {
      document.body.style.overflow = 'unset';
      if (header) header.style.overflowY = 'unset';
    };
  }, [show]);

  if (!mounted || typeof window === 'undefined') return null;

  return createPortal(
    <div
      className={clsx('fixed inset-0 z-[1000] overflow-y-scroll overscroll-y-none', {
        'pointer-events-none no-scrollbar': !show,
      })}
      onClick={onClose}
    >
      <div
        className={clsx('py-[60px] px-[18px] w-full flex justify-center relative', {
          'h-[100vh] items-center': isFormSubmitted,
          'items-start': !isFormSubmitted,
        })}
      >
        <div
          className={clsx('absolute inset-0 z-[-1] bg-black opacity-0 transition-opacity min-h-[100vh]', {
            'opacity-60': show,
          })}
        />

        <div
          className={clsx(
            `overflow-hidden sm:max-w-[1380px] w-full rounded-[16px]
            py-6xl px-2xl mobile:py-6xl mobile:px-6xl 
            bg-gradient-to-br from-[#0E1B3D] to-[#24162F]
            transition-transform duration-[700ms] relative`,
            show ? 'translate-y-0 opacity-100' : 'translate-y-[115vh] opacity-0',
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className={clsx('', {
              'absolute z-[-1] w-full left-0 top-0 overflow-hidden h-full': isFormSubmitted,
            })}
          >
            <div
              className={clsx('relative left-[15px] sm:left-[-20px] top-[-47px] sm:top-[-34px]', {
                'left-[43px] sm:left-[32px]': isFormSubmitted,
              })}
            >
              <ArrowWithPlume
                arrowName="arrow-with-plume-pink"
                classname={`h-[150px] sm:h-[267px] w-[50px] sm:w-[67px] ${show && 'arrow-animation-small'}`}
              />
            </div>

            <div className="absolute bottom-[-178px] sm:bottom-[-243px] right-[197px] sm:right-[232px]">
              <ArrowWithPlume
                arrowName="arrow-with-plume-pink"
                classname={`h-[250px] sm:h-[439px] w-[70px] sm:w-[110px] ${show && 'arrow-animation-large'}`}
              />
            </div>
          </div>

          <div className="flex flex-col items-center gap-y-[15px] relative z-10">
            <Typography
              component="h2"
              variant="h2"
              className="[&>span]:font-monoItalic [&>span]:text-purple-7 [&>span]:tracking-[-1.2px] text-center"
            >
              {title}
            </Typography>

            <Typography
              component="p"
              className="text-[14px] sm:text-[22px] text-white text-center mb-[40px]"
            >
              {subtitle}
            </Typography>
          </div>

          {!isFormSubmitted && descriptionSlot}

          <button
            className="absolute top-[15px] right-[10px] sm:right-[30px] text-grey-6 text-3xl sm:text-4xl"
            onClick={onClose}
          >
            &#10005;
          </button>

          {show && (
            <ContactFrom
              testId="contact-us-popup"
              showPotentialRole={false}
              submitButtonClassName="mt-base"
              setFormSubmissionStatus={setIsFormSubmitted}
              isWidePopup
              isAudit={isAudit}
            />
          )}
        </div>
      </div>
    </div>,
    document.body,
  );
};
