'use client';

import { IFormPopupActionContainerProps } from '@/organisms/01-sections/FormPopupActionContainer/index.types';
import { ContactFormPopup } from '@/components/Common/ContactFormPopup';
import { useState } from 'react';
import parse from 'html-react-parser';

export const FormPopupActionContainer = ({ children }: IFormPopupActionContainerProps) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <ContactFormPopup
        title={parse('Succeed <span>faster</span> with Syberry')}
        subtitle="We aim to deliver an MVP between 6-12 months"
        show={isOpen}
        onClose={() => setOpen(false)}
      />
      <div onClick={() => setOpen(true)}>{children}</div>
    </>
  );
};
